.menu-item {
  min-width: 24vw;
  height: 50vh;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  overflow: hidden;
  background: #f8f8f8;

  &.full{
    height:100vh;
  }
  &:hover {
    cursor: pointer;
    background:#000;
    &.small .background-image {
      transform: scale(1.1);
      transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
      opacity: 0.3;
    }
    &.large{
      background:none;
      opacity: 1;
    }
    & .title{
      transform: translateY(-50px);
    }
    & .content .description {
      transform: translateY(-5vh);
      opacity: 1;
    }
    & .content {
      opacity: 0.9;
    }
  }

  &.small {
    min-width: 24vw;;
  }
  &.large{
    min-width: 49vw;
  }

  .background-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background: #f8f8f8;
    background-size: cover;
  }
  .logo{
    width: 320px;
    margin: auto;
    display: block;
    margin-top: 25px;
  }
  
  .content {
    height: 90px;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #222;
    position: absolute;
    .description{
      transition: 1.2s ease all;
      color:#fff;
      font-family: 'Lato';
      width: 285px;
      position: relative;
      opacity: 0;
    }
    .title {
    text-transform: uppercase;
    font-family: 'Lato';
    transition: transform .9s ease;
    letter-spacing: 4.3px;
    font-size: 17px;
    width: 285px;
    color: #fff;
    text-shadow: 1px 1px 0px #000;
    margin-top:120px;
    }

    .subtitle {
      font-weight: lighter;
      font-size: 16px;
    }
  }
  @media (max-width: 767px) {
    min-width: 100vw;
    &.small{
      min-width: 100vw;
    }
    & .content .description {
      transform: translateY(-15vh);
      opacity: 1;
      color: #D2AA65;
      text-shadow: 1px 1px 0px #A67E38;
    }
  }
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  {
   min-width: 100vw;
    &.small{
      min-width: 100vw;
    }
    & .content .description {
      transform: translateY(3vh);
      opacity: 1;
      color: #D2AA65;
      text-shadow: 1px 1px 0px #A67E38;
    }
}
}
body{
    padding: 0px;
    margin: 0px;
    overflow: hidden;
}
@media (max-width: 767px) {
    body{
        overflow: scroll;
    }
}
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  {
    body{
        overflow: scroll;
    }
}